<template>
    <AssetMarker
        :asset="asset"
        :is-selected="isSelected"
        :interactive="interactive"
        :information-pills="informationPills"
        :visible="visible"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import { formatHelper, measurementHelper } from '@/utils'

import AssetMarker from './AssetMarker'

export default {
    name: 'GenericMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        informationPills() {
            const pills = []
            const assetType = this.asset.asset_details?.asset_type_type
            const sensorData =
                this.asset.asset_details?.sensor_data ?? this.asset.sensor_data

            if (
                assetType === 'flat-roof' ||
                assetType === 'flat-roof-light' ||
                assetType === 'flat-roof-probe'
            ) {
                return []
            } else if (assetType === 'bin') {
                if (sensorData.distance) {
                    const fillLevel = measurementHelper.convertToBinLevel(
                        sensorData.distance.value
                    )
                    let classes = 'sensor-data sensor-data-percentage'
                    if (fillLevel < 40) {
                        classes += ' sensor-data-bin-empty'
                    } else if (fillLevel > 80) {
                        classes += ' sensor-data-bin-full'
                    } else {
                        classes += ' sensor-data-bin-medium'
                    }

                    pills.push({
                        text: fillLevel,
                        className: classes,
                    })
                }
            } else if (assetType === 'agrola-silo' || assetType === 'silo') {
                if (sensorData.fill_level) {
                    const value = Math.round(sensorData.fill_level.value * 100)

                    pills.push({
                        text: value,
                        className:
                            'sensor-data sensor-data-percentage ' +
                            (value < 20
                                ? 'sensor-data-bin-full'
                                : 'sensor-data-bin-empty'),
                    })
                }

                if (assetType === 'silo' && sensorData.mass) {
                    pills.push({
                        text: `${(sensorData.mass.value / 1000).toFixed(2)}t`,
                        className: 'sensor-data',
                    })
                }
            } else if (assetType === 'toilet') {
                if (sensorData.distance) {
                    let classes = 'sensor-data sensor-data-parking'
                    let innerText = this.$t('free')
                    if (sensorData.distance?.value <= 0.8) {
                        classes += ' sensor-data-parking-occupied'
                        innerText = this.$t('occupied')
                    }
                    pills.push({
                        text: innerText,
                        className: classes,
                    })
                }
            } else {
                if (sensorData.mass?.value) {
                    pills.push({
                        text: `${sensorData.mass.value}`,
                        className: 'sensor-data sensor-data-mass',
                    })
                }

                if (sensorData.humidity?.value) {
                    pills.push({
                        text: Math.round(sensorData.humidity.value),
                        className: 'sensor-data sensor-data-percentage',
                    })
                }

                if (sensorData.temperature?.value && assetType !== 'animal') {
                    pills.push({
                        text: Math.round(sensorData.temperature.value),
                        className: 'sensor-data sensor-data-temperature',
                    })
                }

                if (sensorData.distance?.value && assetType !== 'temperature') {
                    pills.push({
                        text: sensorData.distance.value,
                        className: 'sensor-data sensor-data-distance',
                    })
                }

                if (sensorData.co2?.value) {
                    pills.push({
                        text: formatHelper.kFormatter(
                            Math.round(sensorData.co2.value)
                        ),
                        className: 'sensor-data sensor-data-co2',
                    })
                }

                if (sensorData.voc?.value) {
                    pills.push({
                        text: formatHelper.kFormatter(sensorData.voc.value),
                        className: 'sensor-data sensor-data-voc',
                    })
                }
            }

            return pills
        },
    },
}
</script>

<i18n>
{
    "en": {
        "occupied": "Occupied",
        "free": "Free"
    },
    "de": {
        "occupied": "Besetzt",
        "free": "Frei"
    },
    "fr": {
        "occupied": "Occupé",
        "free": "Libre"
    },
    "it": {
        "occupied": "occupato",
        "free": "libero"
    }
}
</i18n>
