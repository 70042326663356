<template>
    <AssetMarker
        :asset="asset"
        :interactive="interactive"
        :is-on="isOn"
        :is-selected="isSelected"
        :visible="visible"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import AssetMarker from './AssetMarker'

export default {
    name: 'TruckMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isOn() {
            const isIgnitionOn = this.asset.asset_details.sensor_data.d1?.value
            const workingState = this.asset.asset_details.sensor_data
                .selected_activity?.value

            return workingState === 0 || workingState === 2
                ? null
                : isIgnitionOn && workingState === 3
                ? true
                : isIgnitionOn === false
                ? false
                : undefined
        },
    },
}
</script>
