<template>
    <AssetMarker
        :asset="asset"
        :is-selected="isSelected"
        :interactive="interactive"
        :visible="visible"
        :information-pills="informationPills"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import AssetMarker from './../../AssetMarker'

export default {
    name: 'LevelAssetMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        level() {
            return this.asset?.asset_details?.sensor_data?.level?.value
        },
        informationPills() {
            return this.level
                ? [
                      {
                          text: this.level + ' m',
                          className: 'sensor-data',
                      },
                  ]
                : []
        },
    },
}
</script>
