<template>
    <LFeatureGroup>
        <LPolyline
            :lat-lngs="waypoints"
            color="#003663"
            :weight="32"
            :opacity="0.25"
        />

        <LPolyline :lat-lngs="waypoints" color="#fff" :weight="10" />

        <LPolyline :lat-lngs="waypoints" color="#00a4e1" :weight="4" />
    </LFeatureGroup>
</template>

<script>
import { LFeatureGroup, LPolyline } from 'vue2-leaflet'

export default {
    name: 'NavigationRoute',
    components: {
        LFeatureGroup,
        LPolyline,
    },
    props: {
        waypoints: {
            type: Array,
            required: true,
        },
    },
}
</script>
