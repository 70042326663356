<template>
    <LFeatureGroup :visible="showTrackerLayer">
        <component :is="markerClusterComponent">
            <template v-for="tracker in trackersToCluster">
                <component
                    :is="chooseTrackerType(tracker)"
                    :key="tracker.id"
                    :asset="tracker"
                    :is-selected="
                        activeTracker && activeTracker.id === tracker.id
                    "
                    :interactive="interactive"
                    :visible="isVisible(tracker.id)"
                    @markerClicked="$emit('markerClicked', tracker.id)"
                />
            </template>
        </component>

        <template v-for="tracker in trackersNotToCluster">
            <component
                :is="chooseTrackerType(tracker)"
                :key="tracker.id"
                :asset="tracker"
                :is-selected="activeTracker && activeTracker.id === tracker.id"
                :interactive="interactive"
                :visible="isVisible(tracker.id)"
                @markerClicked="$emit('markerClicked', tracker.id)"
            />
        </template>
    </LFeatureGroup>
</template>

<script>
import { mapState } from 'vuex'
import { LFeatureGroup } from 'vue2-leaflet'

import AirQualityMarker from './AirQualityMarker'
import AssetMarkerCluster from './AssetMarkerCluster'
import DynamicBinMarker from './asset-types/dynamic-bin/DynamicBinMarker'
import GenericMarker from './GenericMarker'
import LevelAssetMarker from './asset-types/level/LevelAssetMarker'
import MachineMarker from './MachineMarker'
import ParkingSpaceMarker from './ParkingSpaceMarker'
import SbbAssetMarkerCluster from './asset-types/sbb-bin/SbbAssetMarkerCluster'
import SbbBinMarker from './asset-types/sbb-bin/SbbBinMarker'
import TruckMarker from './TruckMarker'

const markerMapping = {
    'air-quality': AirQualityMarker,
    'dynamic-bin': DynamicBinMarker,
    'dynamic-bin-collection': DynamicBinMarker,
    'parking-space': ParkingSpaceMarker,
    'sbb-bin': SbbBinMarker,
    level: LevelAssetMarker,
    machine: MachineMarker,
    truck: TruckMarker,
}

export default {
    name: 'TrackerLayer',
    components: {
        LFeatureGroup,
    },
    props: {
        // Allows to exlude markers from clustering.
        // Provide Array of either Tracker IDs or Tracker Objects (with property 'id').
        excludedFromClustering: {
            type: Array,
            required: false,
            default: () => [],
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        onlyIds: {
            type: Array,
            required: false,
            default: null,
        },
        trackers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showTrackerLayer: false,
        }
    },
    computed: {
        ...mapState('sharing', ['activeSharedTracker']),
        ...mapState('tracker', [
            'activeTrackerOnMap',
            'filterParams',
            'shouldAdjustMapOnFilterChange',
        ]),
        activeTracker() {
            return this.activeSharedTracker ?? this.activeTrackerOnMap
        },
        trackersToCluster() {
            return this.trackers.filter(t => !this.excludedIDs.includes(t.id))
        },
        trackersNotToCluster() {
            return this.trackers.filter(t => this.excludedIDs.includes(t.id))
        },
        excludedIDs() {
            return this.excludedFromClustering
                .filter(item => item)
                .map(item => (item > 0 ? item : item.id))
        },
        markerClusterComponent() {
            return process.env.VUE_APP_ENVIRONMENT_NAME === 'sbb'
                ? SbbAssetMarkerCluster
                : AssetMarkerCluster
        },
    },
    watch: {
        activeTracker: {
            immediate: true,
            handler(tracker) {
                if (tracker) {
                    this.$emit('alignMap', [
                        [
                            tracker.asset_details
                                ? tracker.asset_details.position.latitude
                                : tracker.position.lat,
                            tracker.asset_details
                                ? tracker.asset_details.position.longitude
                                : tracker.position.lng,
                        ],
                    ])
                }
            },
        },
        filterParams() {
            if (this.shouldAdjustMapOnFilterChange) {
                this.$emit(
                    'alignMap',
                    this.trackers.map(tracker => [
                        tracker.asset_details.position.latitude,
                        tracker.asset_details.position.longitude,
                    ])
                )
            }
        },
    },
    mounted() {
        // By setting the Leaflet FeatureGroup to invisible at first, all the nested components (markers) add their
        // layers to this FeatureGroup without it being rendered in the DOM. Then by setting the FeatureGroup
        // visible in the next tick, all the markers get added to the map at once.
        // This seems way faster. Otherwise every marker gets rendered independently and the map updates hundreds of times.
        this.$nextTick(() => {
            this.showTrackerLayer = true
        })
    },
    methods: {
        chooseTrackerType(marker) {
            return (
                markerMapping[marker.asset_details?.asset_type_type] ||
                GenericMarker
            )
        },
        isVisible(id) {
            if (this.onlyIds === null) {
                return true
            }
            return this.onlyIds.includes(id)
        },
    },
}
</script>
