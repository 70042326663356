<template>
    <LMarker :lat-lng="[lat, lng]" :icon="icon" />
</template>

<script>
import { divIcon } from 'leaflet'
import { LMarker } from 'vue2-leaflet'

export default {
    name: 'UserMarker',
    components: {
        LMarker,
    },
    props: {
        lat: {
            type: Number,
            required: true,
        },
        lng: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            icon: divIcon({
                iconSize: [24, 24],
                className: 'leaflet-icon-user-position',
            }),
        }
    },
}
</script>

<style lang="scss">
.leaflet-icon-user-position {
    background-color: #00a4e1;
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 1px 15px rgba(0, 54, 99, 0.25);
}
</style>
