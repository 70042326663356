<template>
    <LFeatureGroup>
        <UserMarker
            v-if="positionTrackingEnabled && lastUserPosition"
            :lat="lastUserPosition.lat"
            :lng="lastUserPosition.lng"
        />

        <NavigationRoute v-if="navigationRoute" :waypoints="navigationRoute" />
    </LFeatureGroup>
</template>

<script>
import { mapState } from 'vuex'
import { LFeatureGroup } from 'vue2-leaflet'

import NavigationRoute from './NavigationRoute'
import UserMarker from './UserMarker'

export default {
    name: 'UserLayer',
    components: {
        LFeatureGroup,
        NavigationRoute,
        UserMarker,
    },
    props: {
        userLocation: {
            type: Object,
            default: null,
            validator: v => !isNaN(v.lat) && !isNaN(v.lng),
        },
    },
    computed: {
        ...mapState('map', [
            'positionTrackingEnabled',
            'lastUserPosition',
            'navigationRoute',
        ]),
    },
}
</script>
