<template>
    <AssetMarker
        :asset="asset"
        :icon-url="iconUrl"
        :interactive="interactive"
        :is-selected="isSelected"
        :visible="visible"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import AssetMarker from './AssetMarker'

import { measurementHelper } from '@/utils'

export default {
    name: 'ParkingSpaceMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        iconUrl() {
            let iconUrl
            if (this.asset?.asset_details?.sensor_data?.distance) {
                iconUrl = measurementHelper.convertToParkingAvailability(
                    this.asset.asset_details.sensor_data.distance.value
                )
                    ? require('@/assets/icons/parking-space-free.svg')
                    : require('@/assets/icons/parking-space-occupied.svg')
            }
            return iconUrl
        },
    },
}
</script>
