<template>
    <LFeatureGroup>
        <LPolyline
            :lat-lngs="coordinates"
            :fill="false"
            :color="activeLayer.traceBorderColor || 'transparent'"
            :weight="4"
            dash-array="7 7"
            :opacity="0.5"
        />

        <LPolyline
            ref="polyline"
            :lat-lngs="coordinates"
            :fill="false"
            :color="activeLayer.traceColor"
            :weight="2"
            dash-array="7 7"
        />
    </LFeatureGroup>
</template>

<script>
import { mapGetters } from 'vuex'
import { LFeatureGroup, LPolyline } from 'vue2-leaflet'

export default {
    name: 'TrackerTraceLayer',
    components: {
        LFeatureGroup,
        LPolyline,
    },
    props: {
        coordinates: {
            type: Array,
            default: null,
        },
    },
    computed: {
        ...mapGetters('map', ['activeLayer']),
    },
}
</script>
