<template>
    <LMarkerCluster
        ref="instance"
        :key="'AssetMarkerCluster' + key"
        :options="clusterOptions"
    >
        <slot />
    </LMarkerCluster>
</template>

<script>
import { mapState } from 'vuex'
import { DivIcon, Point } from 'leaflet'
import LMarkerCluster from 'vue2-leaflet-markercluster'

export default {
    components: {
        LMarkerCluster,
    },
    data() {
        return {
            key: 1,
        }
    },
    computed: {
        ...mapState('map', ['clusteringEnabled']),
        clusterOptions() {
            return {
                zoomToBoundsOnClick: true,
                showCoverageOnHover: false,
                spiderfyOnMaxZoom: true,
                spiderfyDistanceMultiplier: 3.5,
                removeOutsideVisibleBounds: false,
                iconCreateFunction: this.clusterIconCreateFunction,
                ...(!this.clusteringEnabled && { disableClusteringAtZoom: 0 }),
                chunkedLoading: true,
            }
        },
    },
    watch: {
        clusteringEnabled() {
            // Key-changing workaround to force cluster options update on the
            // 'clusteringEnabled' state value change
            this.key += 1
        },
    },
    methods: {
        clusterIconCreateFunction(cluster) {
            const childCount = cluster.getChildCount()
            const errorIcon = require('../assets/icons/fill-warning.svg')
            const includesErrorIcon = cluster
                .getAllChildMarkers()
                .some(item => item.options.icon.options.errorIconInstance)

            return new DivIcon({
                className: 'marker-cluster',
                html: includesErrorIcon
                    ? `<div><span>${childCount}</span><img class="marker-cluster__error-icon" src="${errorIcon}"/></div>`
                    : `<div><span>${childCount}</span></div>`,
                iconSize: new Point(60, 60),
            })
        },
    },
}
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';

.marker-cluster {
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    }

    &::before {
        width: 100%;
        height: 100%;
        background-color: rgba(lighten($color-primary-text, 50%), 0.5);
    }

    &::after {
        margin: 7.5%;
        width: 85%;
        height: 85%;
        background-color: $color-primary-text;
    }

    & > div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 700;
        font-family: 'IBM Plex Sans', sans-serif;
        color: #fff;
        z-index: 1;
    }

    &__error-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0;
        right: 8px;
        border-radius: 50%;
        background-color: #fff;
        padding: 2px;
    }
}
</style>
